import Web3 from "web3";
import Address from "../../../assest/json/address/address.json";
import FounderAbi from "../../../assest/json/abi/abiFounder.json";
import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import btc from "../../../assest/img/button-close.png";
const bigdecimal = require("bigdecimal");


export default function Vesting() {
    const decimal = new bigdecimal.BigDecimal('1000000000000000000');
    const web3 = new Web3(Web3.givenProvider);
    const contract = new web3.eth.Contract(FounderAbi, Address.FounderAddress);
    const [address, setAddress] = useState('');
    const [titleAlert, setTitleAlert] = useState('');
    const [lookedFullAmount, setLookedFullAmount] = useState(0);
    const [amountUnLook, setAmountUnLook] = useState(0);
    const [withdrewAmount, setWithdrewAmount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    useEffect(async () => {
        await getAmountLookedFullAmount();
        await getAmountUnLockAmount();
        await getWithdrewAmount();
    }, [address])

    async function connectWallet() {
        if (window.ethereum) {
            try {
                window.ethereum.enable().then(async function () {
                    const account = await web3.eth.getAccounts();
                    await setAddress(account[0]);
                });
            } catch (e) {

            }
        } else if (window.web3) {
            const account = await web3.eth.getAccounts();
            await setAddress(account[0]);
        } else {
        }
    }

    async function withdrawBlockUnLook() {
        if (address > 0) {
            try {
                contract.methods.releaseAllMyToken().send({
                    from: address
                }).then(async res => {
                    await getAmountUnLockAmount();
                    await getAmountLookedFullAmount();
                    await getWithdrewAmount();
                    setShowModal2(true);
                    setTitleAlert('Withdraw token success.');
                    console.log(res, 'res');
                }).catch(err => {
                    setShowModal(true);
                    setTitleAlert('Withdraw token fail. Please try again later!');
                    console.log(err, 'err');
                })
            } catch (e) {
                console.log("call function releaseAllMyToken fail", e)
            }
        }
    }

    const disconnectWallet = () => {
        setAddress('')
    }

    async function getAmountLookedFullAmount(){
        if (address.length > 0){
            contract.methods.getLockedFullAmount(address).call(function (err, res){
                if (err){
                    console.log("get full amount looked fail", err);
                    return;
                }
                let one = new bigdecimal.BigDecimal(res);
                setLookedFullAmount(Number(one.divide(decimal).toString()));
            })
        }
    }

    async function getAmountUnLockAmount() {
        if (address.length > 0){
            contract.methods.getAvailableAmount(address).call(function (err, res){
                if (err){
                    console.log("get full amount looked fail", err);
                    return;
                }
                let one = new bigdecimal.BigDecimal(res);
                setAmountUnLook(Number(one.divide(decimal).toString()));
            })
        }
    }

    async function getWithdrewAmount() {
        if (address.length > 0){
            contract.methods.getWithdrewAmount(address).call(function (err, res){
                if (err){
                    console.log("get full amount looked fail", err);
                    return;
                }
                let one = new bigdecimal.BigDecimal(res);
                setWithdrewAmount(Number(one.divide(decimal).toString()));
            })
        }
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const closeModal2 = () => {
        setShowModal2(false);
    }

    return (
        <div id="tokenVesting">
            <div className="d-flex flex-column align-items-center justify-content-center wrap">
                <h1>creator distribution portal</h1>
                <form action="">
                    <div className="status">
                        {
                            address.length > 0 ? (
                                <div className="connected">
                                    <div className="walletAddress">
                                        <img src="https://cryptopiece.online/img/metamask-logo.png" alt=""/>
                                        {address.slice(0, 4) + '...' + address.slice(address.length - 4, address.length)}
                                    </div>
                                    <button
                                        className="btn btn-outline btn-grad-3"
                                        type="button"
                                        onClick={disconnectWallet}
                                    >
                                        <span><span>Disconnect</span></span>
                                    </button>
                                </div>
                            ) : (
                                <div
                                    className="notConnect"
                                >
                                    <button
                                        type="button"
                                        className="btn btn-outline btn-grad-3"
                                        onClick={async () => {
                                            await connectWallet();
                                            await getAmountUnLockAmount();
                                            await getAmountLookedFullAmount();
                                            await getWithdrewAmount();
                                        }}
                                    >
                                        <span><span>connect wallet</span></span>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    {
                        address.length > 0 ? (
                            <>
                                <div className="info">
                                    <ul className="list-unstyled">
                                        <li>Total: <strong>{lookedFullAmount} Belly</strong></li>
                                        <li>Remain: <strong>{lookedFullAmount - amountUnLook} Belly</strong></li>
                                        <li>Available: <strong>{amountUnLook} Belly</strong></li>
                                    </ul>
                                </div>
                                <div className="d-grid">
                                    <button
                                        type="button"
                                        className="btn btn-grad-3"
                                        onClick={async () => {
                                            await withdrawBlockUnLook();
                                        }}
                                    >
                                        Claim
                                    </button>
                                </div>
                            </>
                        ) : (
                             <></>
                        )
                    }
                </form>
            </div>
            <Modal show={showModal2} id="buyModal" onHide={closeModal2}>
                <Modal.Body>
                    <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                    {titleAlert}
                </Modal.Body>
            </Modal>
            <Modal show={showModal} id="commonMessageModal" centered size="lg" onHide={closeModal}>
                <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                    <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                    <div>
                        <p>{titleAlert}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
