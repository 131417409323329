import React from 'react';
import Address from '../../assest/json/address/address.json'
class Account {
    createAccount = async (address) => {
        return await fetch(`https://api2.cryptopiece.online/pirate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address.toLowerCase()
            })
        }).then(res => res)
    }

    withdrawReward = async (address, dataThatWasSigned, signature) => {
        return await fetch(
            `https://api2.cryptopiece.online/pirate/withdraw?address=${address}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`
        ).then(res => res.text());
    }

    getAccountByAddress = async (address) => {
        return await fetch(`https://api2.cryptopiece.online/pirate?address=${address.toLowerCase()}`).then(res => res.text())
    }

    getMercByAddress = async (address) => {
        return await fetch(`https://api2.cryptopiece.online/mercenarie?address=${address.toLowerCase()}`).then(res => res.text())
    }

    updateTeamAttack = async (address, selectedList) => {
        return await fetch(`https://api2.cryptopiece.online/pirate/updateTeam`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address.toLowerCase(),
                mercSelected: selectedList,
                updatedAt: 0,
            })
        }).then(res => res.text())
    }

    getAllTokenNFT = async (address) => {
        return await fetch(
            `https://api2-testnet.bscscan.com/api?module=account&action=tokennfttx&contractaddress=${Address.MercenaryAddress}&address=${address}&page=1&offset=700&sort=asc&apikey=NKRVGGX2BCMYSDS2XB4KQE2XMUPWVDUEAR`
        ).then(res => res.text())
    }
}

export default new Account();
