import React from 'react';

class Mercenarie {
    attack = async (number, difficulty, address, dataThatWasSigned, signature) => {
        return await fetch(`https://api2.cryptopiece.online/mercenarie/attack?number=${number}&difficulty=${difficulty}&address=${address.toLowerCase()}&dataThatWasSigned=${dataThatWasSigned}&signature=${signature}`)
            .then(res => res.text())
    }

    getDetailMerc = async (number) => {
        return await fetch(`https://api2.cryptopiece.online/mercenarie/getByTokenId?number=${number}`).then(res => res.text())
    }
}

export default new Mercenarie();
