import Web3 from "web3";
import {useEffect, useState} from "react";
import Account from '../../../../share/account/account';
import abiBelly from '../../../../assest/json/abi/abiBelly.json';
import address from '../../../../assest/json/address/address.json'
import {Link} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import iconBelly from "../../../../assest/img/icon-belly.svg"
import DataContext from "../../../../share/context/context";
import btc from "../../../../assest/img/button-close.png";
import buttonDisconnect from "../../../../assest/img/button-disconnect.png";

const bigdecimal = require("bigdecimal");

function Header(isPage) {
    const web3 = new Web3(Web3.givenProvider);
    const [addressWallet, setAddressWallet] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModalDropDow, setShowModalDropDow] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [timeUpdate, setTimeUpdate] = useState('');

    async function connectWallet(data) {
        const chainId = await web3.eth.getChainId();
        if (window.ethereum) {
            try {
                window.ethereum.enable().then(async function () {
                    if (chainId === 56){
                        const account = await web3.eth.getAccounts();
                        await Account.createAccount(account[0]);
                        await setAddressWallet(account[0]);
                        localStorage.setItem('address', account[0]);
                        await data.getBalanceBelly(account[0]);
                        await data.updateStorage();
                        await data.updateAddress();
                    }else {
                        setShowModal3(true);
                        setTitleAlert("Please use Binance SmartChain TestNet.")
                    }
                });
            } catch (e) {

            }
        } else if (window.web3) {
            if (chainId === 56){
                await data.updateAddress();
            }
        } else {
        }
    }


    useEffect(async () => {
        if (localStorage.getItem('address') !== null) {
            setAddressWallet(localStorage.getItem('address'));
        }
    }, [addressWallet])

    const getHours = (unix_timestamp) => {
        const date = new Date(unix_timestamp);
        const formattedTime = date.toLocaleString();
        setTimeUpdate(formattedTime);
    }

    const updateMercSelected = async (address, mercSelected, data) => {
        const arrUpdate = []
        for (let i = 0; i < mercSelected.length; i++) {
            arrUpdate.push(mercSelected[i].number);
        }
        if (arrUpdate.length > 0) {
            const resData = JSON.parse(await Account.updateTeamAttack(address, arrUpdate));
            if (resData.status === "SUCCESS") {
                setTitleAlert('Your crew has been set! Let\'s hunt down some criminal!');
                setShowModal2(true);
            } else if (resData.status === "ERR") {
                setTitleAlert('An error occurred, please try again later!');
                setShowModal2(true);
                await data.updateMercInfo(address);
            } else if (resData.status === "It is not time yet update") {
                setShowModal2(true);
                setTitleAlert(resData.status);
                getHours(resData.data.timeupdate);
                await data.updateMercInfo(address);
            }
        } else {
            setTitleAlert('You need choose at least 1 Mercenary before click save Button!');
            setShowModal2(true);
        }
    }

    useEffect(() => {
        if (!isPage.saveMerc){
            setShowModal(true);
            setTitleAlert("Your crew has not been saved! Are you sure you want to leave without saving?")
        }
    }, [])

    async function disconnectWallet() {
        localStorage.removeItem('address');
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const closeModal2 = () => {
        setShowModal2(false)
    }

    const closeModal3 = () => {
        setShowModal3(false)
    }

    const closeModalDropDow = () => {
        setShowModalDropDow(false)
    }

    return (
        <DataContext.Consumer>
            {data => (
                <header className="col-auto font-riverAdventurer">
                    <div className="row">
                        <div className="col-auto">
                            <ul className="d-flex list-unstyled">
                                <li className="nav-item">
                                    <Link className={isPage.page === 1 ? "nav-link active" : "nav-link"}
                                          to="/merc_center" onClick={async () => {
                                        if (data.address) {
                                            await data.updateMercInfo(data.address);
                                        }
                                    }}>
                                        <span>Merc Center</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={isPage.page === 2 ? "nav-link active" : "nav-link"}
                                          to="/my_crew" onClick={async () => {
                                        if (data.address) {
                                            await data.updateMercInfo(data.address);
                                        }
                                    }}>
                                        <span>My Crew</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={isPage.page === 3 ? "nav-link active" : "nav-link"}
                                          to="/battle_field" onClick={async () => {
                                        if (data.address) {
                                            await data.updateMercInfo(data.address);
                                        }
                                    }}>
                                        <span>Battle Field</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-auto ms-auto">
                            <ul className="d-flex list-unstyled">
                                {
                                    data.storage === null ? (
                                        <li className="nav-item">
                                            <a className="nav-link" onClick={async () => {
                                                await connectWallet(data);
                                                await data.getBalanceBelly(data.address);
                                                await data.updateStorage();
                                                await data.updateAddress();
                                            }}>
                                                <span>Connect Wallet</span>
                                            </a>
                                        </li>
                                    ) : (
                                        <li className="nav-item">
                                            <a className="nav-link" href="#" onClick={() => {
                                                setShowModalDropDow(true);
                                            }}>
                                                <span>
                                                    Wallet <br/>
                                                    <span className="quantity">{data.blanceBelly}</span>
                                                    <img src={iconBelly} alt=""/>
                                                </span>
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <Modal show={showModal} id="buyModal" onHide={closeModal}>
                        <Modal.Body>
                            <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                            {titleAlert}
                            <div>
                                <div className="button btn btn-sm btn-success" style={{marginRight: 20}} onClick={async () => {
                                    setShowModal(false);
                                    await updateMercSelected(data.address, data.mercSelected, data);
                                }}>
                                    yes
                                </div>
                                <div className="button btn btn-sm btn-danger" onClick={() => {
                                    closeModal();
                                }}>
                                    no
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModal2} id="buyModal" onHide={closeModal2}>
                        <Modal.Body>
                            <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                            {titleAlert}
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModalDropDow} id="disconnectModal" onHide={closeModalDropDow}>
                        <Modal.Body className="disconnectModalFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModalDropDow}/>
                            <img src={buttonDisconnect} alt="" className="buttonDisconnect" onClick={async () => {
                                await disconnectWallet();
                                closeModalDropDow();
                                data.removeStorage();
                                data.updateNewMerc(null);
                            }}/>
                            {
                                data.storage !== null ?(
                                    <div>
                                        {
                                            data.address ? (
                                                <div className="walletAddress">
                                                    {data.address.slice(0, 4) + '...' + data.address.slice(data.address.length - 4, data.address.length)}
                                                </div>
                                            ) : (
                                                <div className="walletAddress"></div>
                                            )
                                        }
                                        <div className="totalBelly font-riverAdventurer">{data.blanceBelly} <img src={iconBelly}
                                                                                                   alt=""/></div>
                                    </div>
                                ) : ""
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal show={showModal3} id="commonMessageModal" centered size="lg" onHide={closeModal3}>
                        <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                            <img className="closeModal" src={btc} alt="" onClick={closeModal3}/>
                            <div>
                                <p>{titleAlert}</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </header>
            )}
        </DataContext.Consumer>
    );
}

export default Header;
