import React from 'react';

class Egg {
    getPrice = async () => {
        return await fetch(`https://api2.cryptopiece.online/config`).then(res => res.text());
    }

    buyEgg = async (address, signature, dataThatWasSigned) => {
        return await fetch(`https://api2.cryptopiece.online/egg`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                address: address.toLowerCase(),
                signature: signature,
                dataThatWasSigned: dataThatWasSigned,
            })
        }).then(res => res.text())
    }
}

export default new Egg();
