import {Modal, Button} from "react-bootstrap";
import Egg from '../../../share/egg/egg'
import abiBelly from '../../../assest/json/abi/abiBelly.json';
import address from '../../../assest/json/address/address.json';
import water from '../../../assest/img/element-water.png';
import DataContext from "../../../share/context/context";
import Web3 from "web3";
import {BigNumber} from "@ethersproject/bignumber";
import {useEffect, useState} from "react";
import fire from "../../../assest/img/element-fire.png";
import earth from "../../../assest/img/element-earth.png";
import metal from "../../../assest/img/element-metal.png";
import wood from "../../../assest/img/element-wood.png";
import dark from "../../../assest/img/element-dark.png";
import light from "../../../assest/img/element-light.png";
import star2 from '../../../assest/img/star.svg';
import mystery from '../../../assest/img/mystery.png'
import bellyIcon2 from '../../../assest/img/icon-belly.svg'
import btb from '../../../assest/img/button-buy.png'
import bsc from '../../../assest/img/button-sign-contract.png'
import btc from '../../../assest/img/button-close.png'
import bccf from '../../../assest/img/button-claim-confirm.png'
import bwd from  '../../../assest/img/button-withdraw.png'
import Header from "../../layout/app/header/Header";
import abiMercenary from "../../../assest/json/abi/abiMercenary.json";
import addressJson from "../../../assest/json/address/address.json";
import Account from "../../../share/account/account";
import Mercenarie from "../../../share/mercenarie/mercenarie"

const bigdecimal = require("bigdecimal");

function MercCenter() {
    const web3 = new Web3(Web3.givenProvider);
    const contractBelly = new web3.eth.Contract(abiBelly, address.BellyAddress);
    const contractMercenary = new web3.eth.Contract(abiMercenary, addressJson.MercenaryAddress);
    const decimal = new bigdecimal.BigDecimal('1000000000000000000');
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalConfirmWithdraw, setShowModalConfirmWithdraw] = useState(false);
    const [titleAlert, setTitleAlert] = useState('');
    const [titleAlert2, setTitleAlert2] = useState('');
    const [loadingText, setLoadingText] = useState('');
    const [amountAllowance, setAmountAllowance] = useState(0);
    const [price, setPrice] = useState(0);
    const [storage, setStorage] = useState(null);

    const approveAmount = async (addressWallet, contextData) => {
        if (localStorage.getItem('address') !== null) {
            try {
                if (addressWallet.length > 0) {
                    contractBelly.methods.balanceOf(addressWallet).call(function (err, res) {
                        if (err) {
                            console.log("Get user balance error!", err);
                            return;
                        }
                        let balance = new bigdecimal.BigDecimal(res);
                        const balanceConvert = Math.floor(Number(balance.divide(decimal).toString()));
                        if (balanceConvert >= price) {
                            contractBelly.methods.approve(
                                address.MercenaryAddress,
                                BigNumber.from(10).pow(18).mul(Math.floor(Number(balance.divide(decimal).toString())).toString()).toString()
                            ).send({
                                from: addressWallet,
                            }).then(async (data) => {
                                if (data.status) {
                                    contractMercenary.methods.buyEgg().send({
                                        from: addressWallet,
                                    }).then(async (res) => {
                                        if (res.status) {
                                            await contextData.getTotalEgg(addressWallet);
                                            setLoading(false);
                                            setShowModal(true);
                                            setTitleAlert('Mercenary Contract purchased successfully!');
                                            await contextData.getBalanceBelly(addressWallet);
                                        }
                                    }).catch(err => {
                                        console.log(err)
                                        setLoading(false)
                                        setShowModal2(true);
                                        setTitleAlert2('Buy Mercenary Contract fail.');
                                    })
                                }
                            }).catch(err => {
                                setLoading(false)
                                setShowModal2(true);
                                setTitleAlert2('Approve Not Successfully!');
                            })
                        } else {
                            setLoading(false)
                            setShowModal2(true);
                            setTitleAlert2(`Your Belly is not enough to buy the Mercenary.
                             Please deposit and approve greater or equal than ${price} Belly again!`);
                        }
                    })
                }
            } catch (err) {
                setLoading(false)
                setShowModal2(true);
                setTitleAlert2('Approve Not Successfully!');
            }
        } else {
            setLoading(false)
            setShowModal2(true);
            setTitleAlert2('No wallet connect');
        }
    }

    useEffect(async () => {
        const configData = JSON.parse(await Egg.getPrice());
        setPrice(configData.price);
        setStorage(localStorage.getItem('address'));
        if (localStorage.getItem('address') !== null) {
            const account = await web3.eth.getAccounts();
            if (account[0]) {
                await getAmountAllowance(account[0]);
            }
        }
    }, [storage])

    const getAmountAllowance = async (addressWallet) => {
        contractBelly.methods.allowance(addressWallet, address.MercenaryAddress).call(function (err, res) {
            if (err) {
                console.log(err);
                return;
            }
            const balance = new bigdecimal.BigDecimal(res);
            setAmountAllowance(Number(balance.divide(decimal).toString()));
        })
    }

    const sign = async (address, data) => {
        if(data.listEgg > 0){
            await awardEgg(address, data);
        }else {
            setTitleAlert2('No Mercenary to sign.');
            setShowModal2(true);
        }
    }

    const awardEgg = async (address, data) => {
        if (localStorage.getItem('address') !== null) {
            setLoadingText('Please wait while we are looking for a suitable Mercenary!!');
            setLoading(true);
            contractMercenary.methods.openEggAndAward().send({
                from: address,
            }).then(async (res) => {
                if(res.status){
                    setLoading(false);
                    const newMerc = JSON.parse(await Mercenarie.getDetailMerc(Number(res.events.Transfer.returnValues.tokenId)))
                    await data.getTotalEgg(address);
                    data.updateNewMerc(newMerc);
                    await data.updateListMerc(data.address, Number(res.events.Transfer.returnValues.tokenId));
                    setTitleAlert('Congratulation! A Mercenary has been hired!');
                    setShowModal(true);
                }else {
                    setLoading(false);
                }
            })
        } else {
            setTitleAlert2('No wallet connect.');
            setShowModal2(true);
        }
    }

    const buyEgg = async (addressWl, data) => {
        if (localStorage.getItem('address') !== null) {
            setLoadingText('Please wait! Your Mercenary Contract will be delivered shortly!');
            setLoading(true);
            contractBelly.methods.allowance(addressWl, address.MercenaryAddress).call(async function (err, res) {
                if (err) {
                    setLoading(false);
                    setShowModal2(true);
                    setTitleAlert2('Connect to Wallet fail! Please check chain of wallet!');
                    console.log(err);
                    return;
                }
                const balance = new bigdecimal.BigDecimal(res);
                setAmountAllowance(Number(balance.divide(decimal).toString()));
                if (Number(balance.divide(decimal).toString()) < price) {
                    const bl = await web3.eth.getBalance(addressWl)
                    const balanceBNB = new bigdecimal.BigDecimal(bl);
                    const balanceBnbConvert = Number(balanceBNB.divide(decimal).toString());
                    if (balanceBnbConvert > 0.05) {
                        await approveAmount(addressWl, data);
                    } else {
                        setLoading(false);
                        setShowModal2(true);
                        setTitleAlert2('Your BNB is not enough to pay fee. Please deposit 0.1 BNB!');
                    }
                } else {
                    contractBelly.methods.balanceOf(addressWl).call(async function (err, res) {
                        if (err) {
                            console.log("Get user balance error!", err);
                            return;
                        }
                        let balanceBl = new bigdecimal.BigDecimal(res);
                        const balanceBLConvert = Math.floor(Number(balanceBl.divide(decimal).toString()));
                        if (balanceBLConvert < price) {
                            setLoading(false);
                            setShowModal2(true);
                            setTitleAlert2(`Your Belly is not enough to buy the Mercenary. Please deposit greater or equal than ${price} Belly again!`);
                            return;
                        }
                        if (Number(balance.divide(decimal).toString()) >= price && balanceBLConvert >= price) {
                            const bl = await web3.eth.getBalance(addressWl)
                            const balanceBNB = new bigdecimal.BigDecimal(bl);
                            const balanceBnbConvert = Number(balanceBNB.divide(decimal).toString());
                            if (balanceBnbConvert > 0.05) {
                                contractMercenary.methods.buyEgg().send({
                                    from: addressWl,
                                }).then(async (res) => {
                                    if (res.status) {
                                        await data.getTotalEgg(addressWl);
                                        await data.getBalanceBelly(addressWl);
                                        setLoading(false);
                                        setShowModal(true);
                                        setTitleAlert('Mercenary Contract purchased successfully!');
                                        // setTimeout(async () => {
                                        //
                                        // }, 15000)
                                    }else {
                                        setLoading(false);
                                    }
                                }).catch((error) => {
                                    console.log(error);
                                    setLoading(false);
                                    setShowModal2(true);
                                    setTitleAlert2('Buy Mercenary Contract fail.');
                                })
                            } else {
                                setLoading(false);
                                setShowModal2(true);
                                setTitleAlert2('Your BNB is not enough to pay fee. Please deposit 0.1 BNB!');
                            }
                        }
                    })
                }
            })
        } else {
            setShowModal2(true);
            setTitleAlert2('No wallet connect!');
        }
    }

    const closeModal = () => {
        setShowModal(false)
    }

    const closeModal2 = () => {
        setShowModal2(false)
    }

    const closeModalConfirmWithdraw = () => {
        setShowModalConfirmWithdraw(false)
    }

    const withdrawReward = async (address, data) => {
        const timeStamp = new Date().getTime();
        setLoading(true);
        setLoadingText('Withdrawing.....');
        await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
            if (error) {
                setLoading(false);
                setShowModal2(true);
                setTitleAlert2('Withdraw reward fail.');
                console.log(error);
                return;
            }
            const withdraw = JSON.parse(await Account.withdrawReward(address, timeStamp, signature));
            if (withdraw.status === 'OK'){
                setTimeout(async () => {
                    await data.updateAccountInfo(address);
                    await data.getBalanceBelly(address);
                    setShowModal(true);
                    setTitleAlert(withdraw.data.message);
                    setLoading(false);
                }, 15000)
            } else {
                setShowModal2(true);
                setTitleAlert2(withdraw.data.message);
                setLoading(false);
            }
        })
    }

    return (
        <DataContext.Consumer>
            {
                data => (
                    <div className="wrap" id="mercCenterPage">
                        <Header page={1} saveMerc={data.saveMercSlt}/>
                        <div id="content" className="col d-flex align-items-center justify-content-center flex-column">
                            {
                                data.amountReward === 0 ? (
                                    <h5 className="withDrawText">
                                        You don't have any belly to withdraw.
                                    </h5>
                                ) : (
                                    <h5 className="withDrawText">
                                        Belly: {data.storage !== null ? data.amountReward : "0"}
                                        <br/>Withdrawal penalty: { data.storage !== null ? data.monetaryFine : '0'}%<br/>
                                        <img className="mt-2" src={bwd} alt="" width="90" onClick={() => {
                                            if (data.storage !== null){
                                                setShowModalConfirmWithdraw(true);
                                            } else {
                                                setShowModal2(true);
                                                setTitleAlert2('No Wallet connect!');
                                            }
                                        }}/>
                                    </h5>

                                )
                            }
                            {
                                data.newMerc === null ? (
                                    <img src={mystery} alt="" className="mystery"/>
                                ) : (
                                    <div className="signSuccess">
                                        <img
                                            className="element"
                                            src={
                                                data.newMerc.element === "fire" ? fire :
                                                    data.newMerc.element === "earth" ? earth :
                                                        data.newMerc.element === "metal" ? metal :
                                                            data.newMerc.element === "water" ? water :
                                                                data.newMerc.element === "wood" ? wood :
                                                                    data.newMerc.element === "dark" ? dark :
                                                                        light
                                            }
                                        />
                                        <img className="character" src={data.newMerc.avatar} alt=""/>
                                        <div className="info">
                                            <div>id: {data.newMerc.number}</div>
                                            <div className="star">
                                                {
                                                    data.checkStarRarity(data.newMerc.rarityNumber).map((e, i) => (
                                                        <img src={star2} alt=""
                                                             key={i}/>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="action d-flex align-items-center">
                            <div className="col buyContract text-end">
                                <div className="row align-items-center justify-content-end g-0">
                                    <div className="col-auto">
                                        <span className="number">{price}</span>
                                        <img className="icon" src={bellyIcon2} alt=""/>
                                        <span className="text">Per contract</span>
                                    </div>
                                    <div className="col-auto">
                                        <button className="btnCustom" onClick={async () => {
                                            await buyEgg(data.address, data);
                                        }}>
                                            <img src={btb} className="btnBuy"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {
                                data.storage === null ? (
                                    <div className="totalContract font-riverAdventurer col-auto">
                                        0 CONTRACTS <span className="text">available</span>
                                    </div>
                                ) : (
                                    <div className="totalContract font-riverAdventurer col-auto">
                                        {data.listEgg} CONTRACTS <span className="text">available</span>
                                    </div>
                                )
                            }
                            <div className="col">
                                <button className="signContract btnCustom" onClick={async () => {
                                    await sign(data.address, data);
                                }}>
                                    <img src={bsc} alt=""/>
                                </button>
                            </div>
                        </div>
                        <Modal show={showModal} id="buyModal" onHide={closeModal}>
                            <Modal.Body>
                                <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                                {titleAlert}
                            </Modal.Body>
                        </Modal>
                        <Modal show={loading} id="commonMessageModal" size="lg">
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <div>
                                    <p>{loadingText}…</p>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModal2} id="commonMessageModal" centered size="lg" onHide={closeModal2}
                               animation={false}>
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal2}/>
                                <div>
                                    <p>{titleAlert2}</p>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModalConfirmWithdraw} id="claimBellyModal" onHide={closeModalConfirmWithdraw}>
                            <Modal.Body>
                                <img className="closeModal" src={btc} alt="" onClick={closeModalConfirmWithdraw}/>
                                <img src={bccf} alt="" className="buttonClaim" onClick={async () => {
                                    closeModalConfirmWithdraw();
                                    if(data.amountReward > 0){
                                        await withdrawReward(data.address, data);
                                    }else {
                                        setShowModal2(true);
                                        setTitleAlert2('No reward to withdraw.');
                                    }
                                }}/>
                                    Early withdrawal will be taxed at {data.monetaryFine}%,
                                equivalent to {data.amountReward * data.monetaryFine / 100} belly.
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            }
        </DataContext.Consumer>
    );
}

export default MercCenter;
