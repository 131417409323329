import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/layout.css';
import MercCenter from "./compoment/screen/mercCenter/MercCenter";
import MyCrew from "./compoment/screen/myCrew/MyCrew";
import WantedRoom from "./compoment/screen/wantedRoom/WantedRoom";
import DataContext from "./share/context/context";
import Account from "./share/account/account";
import {useEffect, useState} from "react";
import Web3 from "web3";
import {Route, Redirect, Switch} from 'react-router-dom'
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import abiBelly from "./assest/json/abi/abiBelly.json";
import abiMercenary from "./assest/json/abi/abiMercenary.json";
import addressJson from "./assest/json/address/address.json";
import Egg from "./share/egg/egg";
import account from "./share/account/account";
import Vesting from "./compoment/layout/vesting/vesting";

const bigdecimal = require("bigdecimal");

const routes = [
    {
        path: '/merc_center',
        component: MercCenter,
    },
    {
        path: '/my_crew',
        component: MyCrew,
    },
    {
        path: '/battle_field',
        component: WantedRoom,
    },
    {
        path: '/token-vesting',
        component: Vesting
    }
]


function App() {
    const decimal = new bigdecimal.BigDecimal('1000000000000000000');
    const [text, setText] = useState(0);
    const [mercList, setMercList] = useState([]);
    const [address, setAddress] = useState('');
    const [mercSelected, setMercSelected] = useState([]);
    const [bellyBalance, setBellyBalance] = useState('');
    const [storage, setStorage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listEgg, setListEgg] = useState(0);
    const web3 = new Web3(Web3.givenProvider);
    const connectContractBelly = new web3.eth.Contract(abiBelly, addressJson.BellyAddress);
    const connectContractmercenary = new web3.eth.Contract(abiMercenary, addressJson.MercenaryAddress);
    const [newMerc, setNewMerc] = useState(null);
    const [saveMercSlt, setSaveMercSlt] = useState(true);
    const [amountReward, setAmountReward] = useState(0);
    const [monetaryFine, setMonetaryFine] = useState(0);


    useEffect(async () => {
        const chainId =  await web3.eth.getChainId()
        if (chainId === 56){
            setStorage(localStorage.getItem('address'));
            const accountAddress = await web3.eth.getAccounts();
            if (accountAddress[0]) {
                setAddress(accountAddress[0]);
                const resdata = await getMercByAddress(accountAddress[0]);
                await getAccountDetail(accountAddress[0], resdata);
                await getBalanceBelly(accountAddress[0]);
                await getTotalEgg(accountAddress[0]);
                await Account.createAccount(accountAddress[0]);
            }
        }
    }, [address])

    useEffect(async () => {
        if (window.ethereum) {
            try {
                // check if the chain to connect to is installed
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
                });
            } catch (error) {
                // This error code indicates that the chain has not been added to MetaMask
                // if it is not, then install it into the user MetaMask
                if (error.code === 4902) {
                    try {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: '0x38',
                                    rpcUrl: 'https://data-seed-prebsc-2-s3.binance.org:8545/',
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error(addError);
                    }
                }
                console.error(error);
            }
        } else {
            // if no window.ethereum then MetaMask is not installed
            alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
        }
    })

    useEffect(() => {
        window.ethereum.on('accountsChanged', async (accounts) => {
            if (accounts.length > 0) {
                setLoading(true);
                localStorage.removeItem('address');
                localStorage.setItem('address', accounts[0]);
                setAddress(accounts[0]);
                const resdata = await getMercByAddress(accounts[0]);
                await getBalanceBelly(accounts[0]);
                await getTotalEgg(accounts[0]);
                setLoading(false);
                await getAccountDetail(accounts[0], resdata);
                updateNewMerc(null);
            } else {
                localStorage.removeItem('address');
                setStorage(null)
                updateNewMerc(null);
            }
        });
    }, [])

    useEffect(() => {
        window.ethereum.on('chainChanged',async (chainId) => {
            if (chainId === '0x38') {
            }else if (chainId === '0x61') {
                localStorage.removeItem('address');
                setStorage(null)
                updateNewMerc(null);
            }
        });
    })

    const getTotalEgg = async (address) => {
        connectContractmercenary.methods.eggs(address).call((err, res) => {
            if (err){
                return;
            }
            setListEgg(res);
        })
    }

    const updateMercInfo = async (address) => {
        const resdata = await getMercByAddress(address);
        await getAccountDetail(address, resdata);
    }

    const getMercByAddress = async (accountAddress) => {
        if (accountAddress){
            const data = JSON.parse(await Account.getMercByAddress(accountAddress));
            setMercList(data);
            return data;
        }
    }

    const updateAddress = async () => {
        const accountAddress = await web3.eth.getAccounts();
        setAddress(accountAddress[0]);
    }

    async function getBalanceBelly(addressWl) {
        if (addressWl) {
            connectContractBelly.methods.balanceOf(addressWl).call(function (err, res) {
                if (err) {
                    console.log("GET balance belly fail");
                    return;
                }
                const balance = new bigdecimal.BigDecimal(res);
                setBellyBalance(Number(balance.divide(decimal).toString()))
                setText(Number(balance.divide(decimal).toString()))
            })
        }
    }

    const updateAccountInfo = async (address) => {
        const a = await Account.getAccountByAddress(address);
        const account = JSON.parse(a);
        setAmountReward(account.totalRewardNotWithdraw);
        const currentDate = new Date().getTime();
        const timeWithdraw = account.timeCountReWard - currentDate;
        if (timeWithdraw > 0){
            setMonetaryFine(Math.round(timeWithdraw / 86400000));
        }else {
            setMonetaryFine(0);
        }
    }

    const getAccountDetail = async (accountAddress, listMerc) => {
        const a = await Account.getAccountByAddress(accountAddress);
        if (a) {
            const account = JSON.parse(a);
            setAmountReward(account.totalRewardNotWithdraw);
            const currentDate = new Date().getTime();
            const timeWithdraw = account.timeCountReWard - currentDate;
            if (timeWithdraw > 0){
                setMonetaryFine(Math.round(timeWithdraw / 86400000));
            }else {
                setMonetaryFine(0);
            }
            await getMercSelected(account.mercSelected, listMerc)
        }
    }

    const getMercSelected = async (mercSelected, listMerc) => {
        if (listMerc.length > 0) {
            let arrMercSelected = [];
            for (let i = 0; i < mercSelected.length; i++) {
                const elm = mercSelected[i];
                const filter = listMerc.filter(e => e.number === Number(elm))
                arrMercSelected.push(filter[0]);
            }
            setMercSelected(arrMercSelected);
            setText(text + 1);
        } else {
            setMercSelected([]);
            setText(text + 1);
        }
    }

    const changeSelectedList = (elm) => {
        const filter = mercSelected.filter(e => e.number === elm.number);
        if (mercSelected.length >= 4 || filter.length > 0) {
            return;
        }
        // console.log(mercSelected)
        mercSelected.push(elm);
        setMercSelected(mercSelected);
        // console.log(mercSelected);
        // console.log(mercSelected, 'bf');
        setText(elm.number);

    }

    const removeItemToSelect = (data) => {
        setMercSelected(mercSelected.filter(e => e.number !== data.number));
        // console.log(mercSelected, 'haha')
        setText(data.number + 0.2);
    }

    const updateStorage = () => {
        setStorage(localStorage.getItem('address'));
    }

    const removeStorage = () => {
        setStorage(null);
    }

    const checkStarRarity = (number) => {
        const startArr = [];
        for (let i = 0; i < number; i++) {
            startArr.push(i);
        }
        return startArr;
    }

    const changeLoading = () => {
        setLoading(true);
    }

    const removeLoading = () => {
        setLoading(false);
    }

    const updateNewMerc = (data) => {
        setNewMerc(data);
    }

    return (
        <>
            <DataContext.Provider
                value={{
                    blanceBelly: bellyBalance,
                    address: address,
                    mercList: mercList,
                    mercSelected: mercSelected,
                    storage: storage,
                    loading: loading,
                    listEgg: listEgg,
                    newMerc: newMerc,
                    saveMercSlt: saveMercSlt,
                    monetaryFine: monetaryFine,
                    amountReward: amountReward,
                    changeSelectedList: changeSelectedList,
                    removeItemToSelect: removeItemToSelect,
                    checkStarRarity: checkStarRarity,
                    updateListMerc: getMercByAddress,
                    updateMercInfo: updateMercInfo,
                    getBalanceBelly: getBalanceBelly,
                    updateStorage: updateStorage,
                    removeStorage: removeStorage,
                    updateAddress: updateAddress,
                    changeLoading: changeLoading,
                    removeLoading: removeLoading,
                    getTotalEgg: getTotalEgg,
                    updateNewMerc: updateNewMerc,
                    updateAccountInfo: updateAccountInfo,
                }}
            >
                <Switch>
                    {
                        routes.map((route, index) => (
                            <Route path={route.path} exact={route.exact} key={index}>
                                <route.component routes={route.routes}/>
                            </Route>
                        ))
                    }
                    <Redirect from="*" to="/merc_center"/>
                    <Redirect from="/" to="/merc_center"/>
                </Switch>
            </DataContext.Provider>
        </>
    );
}

export default App;
