import {Modal, Button} from "react-bootstrap";
import star from '../../../assest/img/star.png';
import arrow from '../../../assest/img/arrow.png';
import arrowDouble from '../../../assest/img/arrow-double-head.png';
import vs from '../../../assest/img/vs.png';
import catnip from '../../../assest/img/cri-catnip.png';
import joker from '../../../assest/img/cri-joker.png';
import lil from '../../../assest/img/cri-lil-rat.png';
import mountain from '../../../assest/img/cri-mountain-bandit.png';
import octochan from '../../../assest/img/cri-octochan.png';
import silver from '../../../assest/img/cri-silver-fox.png';
import tango from '../../../assest/img/cri-tango.png';
import tongued from '../../../assest/img/cri-tri-tongued.png';

import statusWin from '../../../assest/img/status-win.png';
import statusLose from '../../../assest/img/status-lose.png';

import water from '../../../assest/img/element-water.png';
import dark from '../../../assest/img/element-dark.png';
import earth from '../../../assest/img/element-earth.png';
import fire from '../../../assest/img/element-fire.png';
import light from '../../../assest/img/element-light.png';
import metal from '../../../assest/img/element-metal.png';
import wood from '../../../assest/img/element-wood.png';
import DataContext from "../../../share/context/context";
import Mercenarie from "../../../share/mercenarie/mercenarie";
import {useEffect, useState} from 'react';
import shadow from "../../../assest/img/shadow.png";
import Account from "../../../share/account/account";
import Web3 from "web3";
import Header from "../../layout/app/header/Header";
import buttonCl from "../../../assest/img/button-claim.png";
import bellyIcon from "../../../assest/img/icon-belly.svg";
import buttonCloseText from "../../../assest/img/button-close-text.png";
import btc from "../../../assest/img/button-close.png";
import buttonFight from "../../../assest/img/button-fight.png";
import startSVG from "../../../assest/img/star.svg";
import vsT from "../../../assest/img/vs2.png";
import processBar from "../../../assest/img/process-bar.png";

function WantedRoom() {
    const [wantedList, setWantedList] = useState([]);
    const [difficulty, setDifficulty] = useState(0);
    const [showModalWin, setShowModalWin] = useState(false);
    const [showModalLose, setShowModalLose] = useState(false);
    const [mercAttack, setMercAttack] = useState(null);
    const [dataWin, setDataWin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [staminaUsedMer, setStaminaUsedMer] = useState(0);
    const [expRewardMer, setExpRewardMer] = useState(0);
    const [titleAlert, setTitleAlert] = useState('');
    const web3 = new Web3(Web3.givenProvider);
    const [mercList, setMercList] = useState([]);
    const [address, setAddress] = useState('');
    const [mercSelected, setMercSelected] = useState([]);
    const [wantedSelected, setWantedSelected] = useState(null);
    const [attackLoading, setAttackLoading] = useState(false);
    const [winRate, setWinRate] = useState(0);
    const [indexMerSelected, setIndexMerSelected] = useState(null);
    const [totalExp, setTotalExp] = useState(0);
    const [expLandmark, setExpLandmark] = useState(0);

    useEffect(async () => {
        const accountAddress = await web3.eth.getAccounts();
        setAddress(accountAddress[0]);
        checkCookie();
        const resData = await getMercByAddress(accountAddress[0]);
        await getAccountDetail(accountAddress[0], resData);
    }, [address])

    const closeModal = () => {
        setShowModal(false)
    }

    const getMercByAddress = async (accountAddress) => {
        if (accountAddress) {
            const data = JSON.parse(await Account.getMercByAddress(accountAddress));
            setMercList(data);
            return data;
        }
    }

    const getAccountDetail = async (accountAddress, listMerc) => {
        if (accountAddress) {
            const account = JSON.parse(await Account.getAccountByAddress(accountAddress));
            await getMercSelected(account.mercSelected, listMerc)
        }
    }

    const getMercSelected = async (mercSelected, listMerc) => {
        if (listMerc.length > 0) {
            let arrMercSelected = [];
            for (let i = 0; i < mercSelected.length; i++) {
                const elm = mercSelected[i];
                const filter = listMerc.filter(e => e.number === Number(elm))
                arrMercSelected.push(filter[0]);
            }
            setMercSelected(arrMercSelected);
            if (mercAttack !== null) {
                const getMercAttack = arrMercSelected.filter(e => e.number === mercAttack.number);
                const totalExpArr = [
                    30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
                    450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
                    1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
                    3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
                ]
                const expLandmarkArr = [
                    0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
                    3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
                    14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
                    39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
                ]
                setTotalExp(totalExpArr[getMercAttack[0].level - 1])
                setExpLandmark(expLandmarkArr[getMercAttack[0].level - 1])
                setMercAttack(getMercAttack[0]);
            }
        }
    }

    const randomWantedList = () => {
        const totalWanted = [catnip, joker, lil, mountain, octochan, silver, tango, tongued];
        const totalElements = [water, fire, earth, wood, dark, metal, light];
        const elementsText = ['water', 'fire', 'earth', 'wood', 'dark', 'metal', 'light'];
        const randomList = [];
        for (let i = 0; i < 4; i++) {
            const randomWanted = Math.floor(Math.random() * 6);
            const randomElement = Math.floor(Math.random() * 7);
            const obj = {
                wanted: totalWanted[randomWanted],
                element: totalElements[randomElement],
                elementsText: elementsText[randomElement],
                number: i + 1,
            }
            randomList.push(obj);
        }
        setWantedList(randomList);
        return randomList;
    }

    useEffect(() => {
        window.ethereum.on('accountsChanged', async (accounts) => {
            if (accounts.length > 0) {
                setMercAttack(null)
                const accountAddress = await web3.eth.getAccounts();
                setAddress(accountAddress[0]);
            } else {
                setMercAttack(null)
            }
        });
    }, [])

    const sign = async (number, difficulty, address, data, stamina) => {
        const timeStamp = new Date().getTime();
        await web3.eth.personal.sign(timeStamp.toString(), address, async (error, signature) => {
            if (error) {
                console.log(error);
                return;
            }
            await attackWanted(number, difficulty, address, data, stamina, timeStamp, signature);
        })
    }

    const attackWanted = async (number, difficulty, address, data, stamina, dataThatWasSigned, signature) => {
        if (difficulty > 0) {
            if (stamina - staminaUsedMer >= 30) {
                setAttackLoading(true)
                const res = JSON.parse(await Mercenarie.attack(number, difficulty, address, dataThatWasSigned, signature));
                if (res.status === "SUCCESS") {
                    setTimeout(async () => {
                        await data.updateAccountInfo(address);
                        setAttackLoading(false)
                        setShowModalWin(true);
                        setDataWin(res.data);
                        const resData = await getMercByAddress(address);
                        await getAccountDetail(address, resData)
                    }, 10000);
                } else if (res.status === "FAIL") {
                    setTimeout(async () => {
                        setAttackLoading(false)
                        const resData = await getMercByAddress(address);
                        await getAccountDetail(address, resData)
                        setShowModalLose(true);
                    }, 5000)
                } else if (res.status === "ERR") {
                    setAttackLoading(false)
                    setTitleAlert(res.data.message);
                    setShowModal(true);
                }
            } else {
                setShowModal(true);
                setTitleAlert('You don\'t have enough stamina! You need at least 30 stamina to fight a criminal!');
            }
        } else {
            setShowModal(true);
            setTitleAlert('No Enemy has been selected yet! Please choose Enemy before fighting!');
        }
    }

    const updateMecrAttack = async (mecr, index) => {
        if (address) {
            setStaminaUsedMer(0);
            setExpRewardMer(0);
            setMercAttack(mecr);
            setDifficulty(0);
            setWantedSelected(null);
            const totalExpArr = [
                30, 70, 100, 120, 140, 160, 180, 200, 220, 240, 260, 280, 300, 350, 400,
                450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100, 1200, 1300, 1400,
                1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600, 2800, 3000,
                3200, 3400, 3600, 3800, 4000, 4200, 4400, 4600, 4800, 5000,
            ]
            const expLandmarkArr = [
                0, 30, 100, 200, 320, 460, 620, 800, 1000, 1220, 1460, 1720, 2000, 2300, 2650,
                3050, 3500, 4000, 4550, 5150, 5800, 6500, 7300, 8200, 9200, 10300, 11500, 12800,
                14200, 15700, 17300, 19000, 20800, 22700, 24700, 26800, 29000, 31400, 34000, 36800,
                39800, 43000, 46400, 50000, 53800, 57800, 62000, 66400, 71000, 75800,
            ]
            setTotalExp(totalExpArr[mecr.level - 1])
            setExpLandmark(expLandmarkArr[mecr.level - 1])
            if (index === 1) {

                setWantedList(JSON.parse(localStorage.getItem("mercSelected1")));
            } else if (index === 2) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected2")))
            } else if (index === 3) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected3")))
            } else if (index === 4) {
                setWantedList(JSON.parse(localStorage.getItem("mercSelected4")))
            }
        }
    }

    const closeModalWin = () => {
        setShowModalWin(false)
    }

    const closeModalLose = () => {
        setShowModalLose(false)
    }

    const updateDifficulty = async (value) => {
        if (localStorage.getItem('address') !== null) {
            setDifficulty(value);
            const data = {};
            data.wanted = wantedList[value - 1].wanted;
            data.element = wantedList[value - 1].element;
            data.number = wantedList[value - 1].number;
            data.elementsText = wantedList[value - 1].elementsText;
            if (value - 1 === 0) {
                data.winRate = 85;
            } else if (value - 1 === 1) {
                data.winRate = 70;
            } else if (value - 1 === 2) {
                data.winRate = 50;
            } else if (value - 1 === 3) {
                data.winRate = 20;
            }
            changeWinRate(data.winRate, mercAttack, data.elementsText);
            setWantedSelected(data)
        } else {
            setShowModal(true);
            setTitleAlert('No wallet connect');
        }
    }

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        const cookieTime = getCookie("timeStamp");
        let currecntTime = new Date().getTime();
        if (cookieTime !== "") {
            if (currecntTime - Number(cookieTime) > 3600000) {
                setCookie("timeStamp", currecntTime, 30);
                for (let i = 0; i < 4; i++) {
                    localStorage.setItem(`mercSelected${i+1}`,JSON.stringify(randomWantedList()));
                    localStorage.setItem(`winRate1Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate2Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate3Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate4Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate5Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate6Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate7Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate8Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate9Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`winRate10Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                    localStorage.setItem(`randomUpDowIndex${i}`, Math.floor(Math.random() * (2 - 1 + 1)) + 1);
                    localStorage.setItem(`randomLightDarkIndex${i}`, Math.floor(Math.random() * (10 + 1)));
                }
            } else {
                setWantedList(null)
            }
        } else {
            let time = new Date().getTime();
            setCookie("timeStamp", time, 30);
            for (let i = 0; i < 4; i++) {
                localStorage.setItem(`mercSelected${i+1}`,JSON.stringify(randomWantedList()));
                localStorage.setItem(`winRate1Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate2Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate3Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate4Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate5Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate6Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate7Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate8Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate9Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`winRate10Index${i}`, Math.floor(Math.random() * (10 - 5 + 1)) + 5);
                localStorage.setItem(`randomUpDowIndex${i}`, Math.floor(Math.random() * (2 - 1 + 1)) + 1);
                localStorage.setItem(`randomLightDarkIndex${i}`, Math.floor(Math.random() * (10 + 1)));
            }
        }
    }

    const changeWinRate = (currentWinRate, merElement, wantedElement) => {
        if (merElement !== null) {
            let winRate = currentWinRate;
            if (merElement.element === 'fire' && wantedElement === 'water') {
                winRate = winRate - Number(localStorage.getItem(`winRate1Index${indexMerSelected}`));
            } else if (merElement.element === 'fire' && wantedElement === 'wood') {
                winRate = winRate + Number(localStorage.getItem(`winRate2Index${indexMerSelected}`));
            } else if (merElement.element === 'earth' && wantedElement === 'metal') {
                winRate = winRate - Number(localStorage.getItem(`winRate3Index${indexMerSelected}`));
            } else if (merElement.element === 'earth' && wantedElement === 'water') {
                winRate = winRate + Number(localStorage.getItem(`winRate4Index${indexMerSelected}`));
            } else if (merElement.element === 'water' && wantedElement === 'earth') {
                winRate = winRate - Number(localStorage.getItem(`winRate5Index${indexMerSelected}`));
            } else if (merElement.element === 'water' && wantedElement === 'fire') {
                winRate = winRate + Number(localStorage.getItem(`winRate6Index${indexMerSelected}`));
            } else if (merElement.element === 'metal' && wantedElement === 'wood') {
                winRate = winRate - Number(localStorage.getItem(`winRate7Index${indexMerSelected}`));
            } else if (merElement.element === 'metal' && wantedElement === 'earth') {
                winRate = winRate + Number(localStorage.getItem(`winRate8Index${indexMerSelected}`));
            } else if (merElement.element === 'wood' && wantedElement === 'fire') {
                winRate = winRate - Number(localStorage.getItem(`winRate9Index${indexMerSelected}`));
            } else if (merElement.element === 'wood' && wantedElement === 'metal') {
                winRate = winRate + Number(localStorage.getItem(`winRate10Index${indexMerSelected}`));
            } else if ((merElement.element === 'light' && wantedElement === 'dark') || (merElement === 'dark' && wantedElement === 'light')) {
                const randomUpDow = localStorage.getItem(`randomUpDowIndex${indexMerSelected}`);
                if (randomUpDow === 1) {
                    winRate = winRate - Number(localStorage.getItem(`randomLightDarkIndex${indexMerSelected}`));
                } else {
                    winRate = winRate + Number(localStorage.getItem(`randomLightDarkIndex${indexMerSelected}`));
                }
            }
            setWinRate(winRate);
        } else {
            setWinRate(currentWinRate);
        }
    }

    return (
        <DataContext.Consumer>
            {
                data => (
                    <div className="wrap" id="battleFieldPage">
                        <Header page={3} saveMerc={data.saveMercSlt}/>
                        <div id="content" className="col d-flex align-items-center justify-content-end">
                            <div className="battleFieldFrame d-flex">
                                <div className="yourTeam">
                                    {
                                        data.storage !== null && data.mercSelected.length > 0 && data.mercSelected ? (
                                            <div className="listTeam d-flex justify-content-center">
                                                {
                                                    mercSelected.map((e, i) => (
                                                        <div
                                                            className={mercAttack ? mercAttack.number === e.number ? "merc  selected" : "merc" : "merc"}
                                                            onClick={async () => {
                                                                await updateMecrAttack(e, i + 1, data);
                                                                setIndexMerSelected(i);
                                                                if (wantedSelected !== null) {
                                                                    changeWinRate(wantedSelected.winRate, e, wantedSelected.elementsText);
                                                                }
                                                            }}
                                                            key={i}
                                                        >
                                                            <img
                                                                src={
                                                                    e.element === "fire" ? fire :
                                                                        e.element === "earth" ? earth :
                                                                            e.element === "metal" ? metal :
                                                                                e.element === "water" ? water :
                                                                                    e.element === "wood" ? wood :
                                                                                        e.element === "dark" ? dark :
                                                                                            light
                                                                }
                                                                alt=""
                                                                className="element"
                                                            />
                                                            <div className="thumbMercWrap">
                                                                <img src={e.avatar} alt=""/>
                                                            </div>
                                                            <div className="star">
                                                                {
                                                                    data.checkStarRarity(e.rarityNumber).map((elm, i) => (
                                                                        <img src={startSVG} alt=""
                                                                             key={i}/>
                                                                    ))
                                                                }
                                                            </div>
                                                            {
                                                                mercAttack !== null && mercAttack.number === e.number ? (
                                                                    <div className="text">selected</div>
                                                                ) : ""
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : (
                                            <div className="listTeam d-flex justify-content-center">
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                                <div className="merc">
                                                    <div className="thumbMercWrap"/>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    data.storage !== null ? (
                                        <div className="col mercTeam d-flex justify-content-start">
                                            {
                                                mercAttack !== null ? (
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="charId">
                                                                <img
                                                                    src={
                                                                        mercAttack.element === "fire" ? fire :
                                                                            mercAttack.element === "earth" ? earth :
                                                                                mercAttack.element === "metal" ? metal :
                                                                                    mercAttack.element === "water" ? water :
                                                                                        mercAttack.element === "wood" ? wood :
                                                                                            mercAttack.element === "dark" ? dark :
                                                                                                light
                                                                    }
                                                                    alt=""
                                                                    className="element"
                                                                />
                                                                id: {mercAttack.number}
                                                            </div>
                                                        </div>
                                                        <div className="col d-flex">
                                                            <div className="charInfo">
                                                                <div className="star">
                                                                    {
                                                                        data.checkStarRarity(mercAttack.rarityNumber).map((elm, i) => (
                                                                            <img src={startSVG} alt=""
                                                                                 key={i}/>
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div className="charStamina">
                                                                    <div
                                                                        className="d-flex justify-content-between text">
                                                                        <div>stamina: {mercAttack.stamina - staminaUsedMer}/150</div>
                                                                        <div>{Math.floor((mercAttack.stamina - staminaUsedMer) * 100 / 150)}%</div>
                                                                    </div>
                                                                    <div className="processBar">
                                                                        <div className="percent"
                                                                             style={{width: `${Math.floor((mercAttack.stamina - staminaUsedMer) * 100 / 150)}%`}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="charXp">
                                                                    <div
                                                                        className="d-flex justify-content-between text">
                                                                        <div>Xp: {(mercAttack.exp + expRewardMer - expLandmark)}/{totalExp} -
                                                                            LV.{mercAttack.level}</div>
                                                                        <div>{Math.floor((mercAttack.exp + expRewardMer - expLandmark) * 100 / totalExp)}%</div>
                                                                    </div>
                                                                    <div className="processBar">
                                                                        <div className="percent"
                                                                             style={{width: `${Math.floor((mercAttack.exp + expRewardMer - expLandmark) * 100 / totalExp)}%`}}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col align-self-end">
                                                                <img className="mainImg" src={mercAttack.avatar}
                                                                     alt=""/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="row">

                                                    </div>
                                                )
                                            }
                                        </div>
                                    ) : (
                                        <div className="col mercTeam d-flex justify-content-start">

                                        </div>
                                    )
                                }
                                <div className="mercenary d-flex align-items-end justify-content-around">
                                    <div className="d-flex align-items-center">
                                        <div><img src={water} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={fire} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={metal} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={wood} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={earth} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={water} alt="" className="element"/></div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div><img src={light} alt="" className="element"/></div>
                                        <div><img src={arrow} alt="" className="arrow"/></div>
                                        <div><img src={arrow} alt="" className="arrow arrow-left"/></div>
                                        <div><img src={dark} alt="" className="element"/></div>
                                    </div>
                                </div>
                                <div className="col-auto align-self-end text-center">
                                    <div className="winingRate">
                                        <img src={vsT} alt=""/>
                                        {
                                            wantedSelected !== null && data.storage !== null ? (
                                                <div className="text">Wining rate: {winRate}%</div>
                                            ) : (
                                                <div className="text">

                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="fightFrame">
                                        <button className="btnFight btnCustom"
                                                onClick={async () => {
                                                    if (localStorage.getItem('address') !== null) {
                                                        if (mercAttack !== null) {
                                                            await sign(mercAttack.number, difficulty, data.address, data, mercAttack.stamina);
                                                        } else {
                                                            setShowModal(true);
                                                            setTitleAlert('No Mercenary has been selected yet! Please choose Mercenary before fighting!');
                                                        }
                                                    } else {
                                                        setTitleAlert('No wallet connect.');
                                                        setShowModal(true);
                                                    }
                                                }}
                                        >
                                            <img src={buttonFight} alt=""/>
                                        </button>
                                    </div>
                                </div>
                                <div className="col criTeam d-flex justify-content-end">
                                    <div className="d-flex">
                                        <div className="col align-self-end">
                                            {
                                                wantedSelected !== null && data.storage !== null ? (
                                                    <img className="mainImg" src={wantedSelected.wanted} alt=""/>
                                                ) : ""
                                            }
                                        </div>
                                        <div className="col-auto align-self-center">
                                            {
                                                data.storage !== null && wantedList ? (
                                                    <ul className="listCri list-unstyled">
                                                        {
                                                            wantedList.map((e, i) => (
                                                                <li
                                                                    className={wantedSelected !== null && wantedSelected.number === e.number ? "selected" : ""}
                                                                    onClick={async () => {
                                                                        await updateDifficulty(i + 1);
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    <img src={e.element} alt="" className="element"/>
                                                                    <div className="thumbCriWrap">
                                                                        <img src={e.wanted} alt=""/>
                                                                    </div>
                                                                    {
                                                                        wantedSelected !== null && wantedSelected.number === e.number ? (
                                                                            <div className="text">selected</div>
                                                                        ) : ""
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                ) : (
                                                    <ul className="listCri list-unstyled">
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="thumbCriWrap">
                                                                <img src={shadow} alt=""/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal show={showModalWin} id="winModal" centered size="lg" onHide={closeModalWin}>
                            <Modal.Body className="winFrame">
                                <img className="closeModal" src={buttonCl} alt="" onClick={closeModalWin}/>
                                Congratulation! <br/> You captured a criminal! You earned <br/>
                                {
                                    dataWin === null ? (
                                        <div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div
                                                className="d-flex justify-content-center align-items-center bellyReceived">
                                                <div className="font-riverAdventurer">{Math.floor(dataWin.reward)}</div>
                                                <img src={bellyIcon} alt=""/>
                                            </div>
                                            <div className="xpReceived">+{dataWin.exp} XP</div>
                                        </div>
                                    )
                                }
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModalLose} onHide={closeModalLose} id="lossModal" centered size="lg">
                            <Modal.Body className="lossFrame">
                                <img className="closeModal" src={buttonCloseText} alt="" onClick={closeModalLose}/>
                                Too bad, the criminal has escaped!
                            </Modal.Body>
                        </Modal>
                        <Modal show={showModal} id="commonMessageModal" centered size="lg" onHide={closeModal}>
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <img className="closeModal" src={btc} alt="" onClick={closeModal}/>
                                <div>
                                    <p>{titleAlert}</p>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={attackLoading} id="commonMessageModal" centered size="lg">
                            <Modal.Body className="commonMessageFrame d-flex align-items-center justify-content-center">
                                <div>
                                    <p>You found a wanted criminal! Please wait for fight result…</p>
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            }
        </DataContext.Consumer>
    );
}

export default WantedRoom;
